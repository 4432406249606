.btn-delete {
    background-color: #FE3824;
    padding: 10px 0px;

    @extend .btn-danger;
}

.btn-edit {
    background-color: transparent;
    color: #F09A28;
    font-size: 15px;
    padding: 2px 5px;
    outline: 0 !important;

    &:hover, &:focus {
        outline: 0;
        color: #F09A28;
    }
}

.item-price, .item-header, .item-name {
    color: #353535; 
    font-size: 16px; 
    font-weight: bold;
}
.item-desc-damage {
    margin-bottom: 1.5rem;
}

.item-name, .item-desc-damage {
    input {
        width: 25%;
    }
}

.delete-image {
    display: none;
    position: absolute;
    bottom: 35px;
    right: 20px;
    background-color: transparent;
    border: none;

    &.active {
        display: block;
    }
}
#app{
    margin-left: 0;
    margin-right: 0;
}