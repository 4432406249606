.hero-btn-toggle {
    text-align: left;
    width: 300px;
    background-color: transparent;
    border-color: transparent;
    outline: 0;

    @extend .center-block;

    h2 {
        font-size: 20px;
        color: #A4AAB3;
    }

    h3 {
        font-size: 12px;
        color: #A4AAB3;
    }

    img {
        padding: 10px;
    }

    img.inactive {
        display: block;
    }

    img.active {
        display: none;
    }

    .padded {
        padding: 10px;
        padding-left: 0px;
    }

    &.active {
        h2 {
            color: #fff;
        }
    
        h3 {
            color: #fff;
        }

        img.active {
            display: block;
        }

        img.inactive { 
            display: none;
        }
    }

    &--wide {
        width: 320px;
    }
}

.square {
    display: inline-block;
    background-color: $primary;
    transform: rotate(-45deg);
    height: 20px;
    width: 20px;
    margin-top: -30px;
    margin-left: -10px;
    top: 0;
    position: absolute;

    z-index: 0;

    transition: all 0.5s;

    &.active {
        margin-top: -10px;
    }
}

.bg-primary-footer {
    background-color: $primary !important;
    color: white;
}

.job-create-form {
    h2 {
        color: #363636;
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 15px;
    }

    hr {
        background-color: #C7C7CD;
        margin: 40px 0px;
    }
}

:root .select2-container .select2-selection--single {
    height: 36px;
    border: 1px solid #ccd0d2;
    border-radius: 4px;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;

    outline: 0 !important;

    .select2-selection__rendered {
        line-height: 36px;
    }

    .select2-selection__arrow {
        height: 36px;
        width: 32px;
    }
}

.btn {
    &--new-claimant {
        color: #363636;
        background-color: #C7C7CD;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        margin-left: -6px;
        z-index: 10;
        position: relative;
        font-weight: bold;
        padding: 6px 20px;

        &:hover {
            color: #363636;
        }
    }
}

.same-height {
    height: 276px !important;
}

.control-select2 {
    .form-control {
        float: left;
        width: calc(100% - 140px) !important;
    }

    .select2 {
        width: calc(100% - 140px) !important;
    }
}

.push-down {
    margin-top: 38px;
}

.dropdown {
    width: 100%;

    .dropdown-toggle {
        width: 100%;
        background-color: white !important;
    }
}

.panel {
    &--background {
        border: none;
        background-color: #C7C7CD;
    }
}

.panel-body {
    border-bottom: 1px solid lightgray;
    &--background {
        background-color: white;

        .panel-heading {
            background-color: white;
        }
    }
}

.text-primary {
    color: $primary !important;
}

.btn-trash { 
    border: none;
    background-color: transparent;

    padding: 0;
    height: 20px;

    i {
        color: red;
        font-size: 20px;
    }
}

.label-full-width {
    width: 100%;
    margin-top: 5px;
}

.job-types {
    display: none;

    &.active {
        display: block;
    }
}