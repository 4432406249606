$input-color: $grey-darker !default;
$input-background-color: $white !default;
$input-border-color: $grey-lighter !default;
$input-shadow: inset 0 1px 2px rgba($black, 0.1) !default;

$input-hover-color: $grey-darker !default;
$input-hover-border-color: $grey-light !default;

$input-focus-color: $grey-darker !default;
$input-focus-border-color: $link !default;
$input-focus-box-shadow-size: 0 0 0 0.125em !default;
$input-focus-box-shadow-color: rgba($link, 0.25) !default;

$input-disabled-color: $text-light !default;
$input-disabled-background-color: $background !default;
$input-disabled-border-color: $background !default;

$input-arrow: $link !default;

$input-icon-color: $grey-lighter !default;
$input-icon-active-color: $grey !default;

$input-radius: $radius !default;

$file-border-color: $border !default;
$file-radius: $radius !default;

$file-cta-background-color: $white-ter !default;
$file-cta-color: $grey-dark !default;
$file-cta-hover-color: $grey-darker !default;
$file-cta-active-color: $grey-darker !default;

$file-name-border-color: $border !default;
$file-name-border-style: solid !default;
$file-name-border-width: 1px 1px 1px 0 !default;
$file-name-max-width: 16em !default;

$label-color: $grey-darker !default;
$label-weight: $weight-bold !default;

$help-size: $size-small !default;

@mixin input {
  @include control;
  background-color: $input-background-color;
  border-color: $input-border-color;
  color: $input-color;
  &:hover,
  &.is-hovered {
    border-color: $input-hover-border-color; }
  &:focus,
  &.is-focused,
  &:active,
  &.is-active {
    border-color: $input-focus-border-color;
    box-shadow: $input-focus-box-shadow-size $input-focus-box-shadow-color; }
  &[disabled] {
    background-color: $input-disabled-background-color;
    border-color: $input-disabled-border-color;
    box-shadow: none;
    color: $input-disabled-color;
    @include placeholder {
      color: rgba($input-disabled-color, 0.3); } } }

.input,
.textarea {
  @include input;
  box-shadow: $input-shadow;
  max-width: 100%;
  width: 100%;
  &[type="search"] {
    border-radius: 290486px; }
  &[readonly] {
    box-shadow: none; }
  // Colors
  @each $name, $pair in $colors {
    $color: nth($pair, 1);
    &.is-#{$name} {
      border-color: $color;
      &:focus,
      &.is-focused,
      &:active,
      &.is-active {
        box-shadow: $input-focus-box-shadow-size rgba($color, 0.25); } } }
  // Sizes
  &.is-small {
    @include control-small; }
  &.is-medium {
    @include control-medium; }
  &.is-large {
    @include control-large; }
  // Modifiers
  &.is-fullwidth {
    display: block;
    width: 100%; }
  &.is-inline {
    display: inline;
    width: auto; } }

.input {
  &.is-static {
    background-color: transparent;
    border-color: transparent;
    box-shadow: none;
    padding-left: 0;
    padding-right: 0; } }

.textarea {
  display: block;
  max-width: 100%;
  min-width: 100%;
  padding: 0.625em;
  resize: vertical;
  &:not([rows]) {
    max-height: 600px;
    min-height: 120px; }
  &[rows] {
    height: unset; }
  // Modifiers
  &.has-fixed-size {
    resize: none; } }

.checkbox,
.radio {
  cursor: pointer;
  display: inline-block;
  line-height: 1.25;
  position: relative;
  input {
    cursor: pointer; }
  &:hover {
    color: $input-hover-color; }
  &[disabled] {
    color: $input-disabled-color;
    cursor: not-allowed; } }

.radio {
  & + .radio {
    margin-left: 0.5em; } }

.select {
  display: inline-block;
  max-width: 100%;
  position: relative;
  vertical-align: top;
  &:not(.is-multiple) {
    height: 2.25em;
    &::after {
      @include arrow($input-arrow);
      margin-top: -0.375em;
      right: 1.125em;
      top: 50%;
      z-index: 4; } }
  select {
    @include input;
    cursor: pointer;
    display: block;
    font-size: 1em;
    max-width: 100%;
    outline: none;
    &::-ms-expand {
      display: none; }
    &[disabled]:hover {
      border-color: $input-disabled-border-color; }
    &:not([multiple]) {
      padding-right: 2.5em; }
    &[multiple] {
      height: unset;
      padding: 0;
      option {
        padding: 0.5em 1em; } } }
  // States
  &:hover {
    &::after {
      border-color: $input-hover-color; } }
  // Colors
  @each $name, $pair in $colors {
    $color: nth($pair, 1);
    &.is-#{$name} select {
      border-color: $color;
      &:focus,
      &.is-focused,
      &:active,
      &.is-active {
        box-shadow: $input-focus-box-shadow-size rgba($color, 0.25); } } }
  // Sizes
  &.is-small {
    @include control-small; }
  &.is-medium {
    @include control-medium; }
  &.is-large {
    @include control-large; }
  // Modifiers
  &.is-disabled {
    &::after {
      border-color: $input-disabled-color; } }
  &.is-fullwidth {
    width: 100%;
    select {
      width: 100%; } }
  &.is-loading {
    &::after {
      @include loader;
      margin-top: 0;
      position: absolute;
      right: 0.625em;
      top: 0.625em;
      transform: none; }
    &.is-small:after {
      font-size: $size-small; }
    &.is-medium:after {
      font-size: $size-medium; }
    &.is-large:after {
      font-size: $size-large; } } }

.file {
  @include unselectable;
  align-items: stretch;
  display: flex;
  justify-content: flex-start;
  position: relative;
  // Colors
  @each $name, $pair in $colors {
    $color: nth($pair, 1);
    $color-invert: nth($pair, 2);
    &.is-#{$name} {
      .file-cta {
        background-color: $color;
        border-color: transparent;
        color: $color-invert; }
      &:hover,
      &.is-hovered {
        .file-cta {
          background-color: darken($color, 2.5%);
          border-color: transparent;
          color: $color-invert; } }
      &:focus,
      &.is-focused {
        .file-cta {
          border-color: transparent;
          box-shadow: 0 0 0.5em rgba($color, 0.25);
          color: $color-invert; } }
      &:active,
      &.is-active {
        .file-cta {
          background-color: darken($color, 5%);
          border-color: transparent;
          color: $color-invert; } } } }
  // Sizes
  &.is-small {
    font-size: $size-small; }
  &.is-medium {
    font-size: $size-medium;
    .file-icon {
      .fa {
        font-size: 21px; } } }
  &.is-large {
    font-size: $size-large;
    .file-icon {
      .fa {
        font-size: 28px; } } }
  // Modifiers
  &.has-name {
    .file-cta {
      border-bottom-right-radius: 0;
      border-top-right-radius: 0; }
    .file-name {
      border-bottom-left-radius: 0;
      border-top-left-radius: 0; } }
  &.is-centered {
    justify-content: center; }
  &.is-right {
    justify-content: flex-end; }
  &.is-boxed {
    .file-label {
      flex-direction: column; }
    .file-cta {
      flex-direction: column;
      height: auto;
      padding: 1em 3em; }
    .file-name {
      border-width: 0 1px 1px; }
    .file-icon {
      height: 1.5em;
      width: 1.5em;
      .fa {
        font-size: 21px; } }
    &.is-small {
      .file-icon .fa {
        font-size: 14px; } }
    &.is-medium {
      .file-icon .fa {
        font-size: 28px; } }
    &.is-large {
      .file-icon .fa {
        font-size: 35px; } }
    &.has-name {
      .file-cta {
        border-radius: $file-radius $file-radius 0 0; }
      .file-name {
        border-radius: 0 0 $file-radius $file-radius;
        border-width: 0 1px 1px; } } }
  &.is-right {
    .file-cta {
      border-radius: 0 $file-radius $file-radius 0; }
    .file-name {
      border-radius: $file-radius 0 0 $file-radius;
      border-width: 1px 0 1px 1px;
      order: -1; } }
  &.is-fullwidth {
    .file-label {
      width: 100%; }
    .file-name {
      flex-grow: 1;
      max-width: none; } } }

.file-label {
  align-items: stretch;
  display: flex;
  cursor: pointer;
  justify-content: flex-start;
  overflow: hidden;
  position: relative;
  &:hover {
    .file-cta {
      background-color: darken($file-cta-background-color, 2.5%);
      color: $file-cta-hover-color; }
    .file-name {
      border-color: darken($file-name-border-color, 2.5%); } }
  &:active {
    .file-cta {
      background-color: darken($file-cta-background-color, 5%);
      color: $file-cta-active-color; }
    .file-name {
      border-color: darken($file-name-border-color, 5%); } } }

.file-input {
  height: 0.01em;
  left: 0;
  outline: none;
  position: absolute;
  top: 0;
  width: 0.01em; }

.file-cta,
.file-name {
  @include control;
  border-color: $file-border-color;
  border-radius: $file-radius;
  font-size: 1em;
  padding-left: 1em;
  padding-right: 1em;
  white-space: nowrap; }

.file-cta {
  background-color: $file-cta-background-color;
  color: $file-cta-color; }

.file-name {
  border-color: $file-name-border-color;
  border-style: $file-name-border-style;
  border-width: $file-name-border-width;
  display: block;
  max-width: $file-name-max-width;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis; }

.file-icon {
  align-items: center;
  display: flex;
  height: 1em;
  justify-content: center;
  margin-right: 0.5em;
  width: 1em;
  .fa {
    font-size: 14px; } }

.label {
  color: $label-color;
  display: block;
  font-size: $size-normal;
  font-weight: $label-weight;
  &:not(:last-child) {
    margin-bottom: 0.5em; }
  // Sizes
  &.is-small {
    font-size: $size-small; }
  &.is-medium {
    font-size: $size-medium; }
  &.is-large {
    font-size: $size-large; } }

.help {
  display: block;
  font-size: $help-size;
  margin-top: 0.25rem;
  @each $name, $pair in $colors {
    $color: nth($pair, 1);
    &.is-#{$name} {
      color: $color; } } }

// Containers

.field {
  &:not(:last-child) {
    margin-bottom: 0.75rem; }
  // Modifiers
  &.has-addons {
    display: flex;
    justify-content: flex-start;
    .control {
      &:not(:last-child) {
        margin-right: -1px; }
      &:first-child {
        .button,
        .input,
        .select select {
          border-bottom-left-radius: $input-radius;
          border-top-left-radius: $input-radius; } }
      &:last-child {
        .button,
        .input,
        .select select {
          border-bottom-right-radius: $input-radius;
          border-top-right-radius: $input-radius; } }
      .button,
      .input,
      .select select {
        border-radius: 0;
        &:hover,
        &.is-hovered {
          z-index: 2; }
        &:focus,
        &.is-focused,
        &:active,
        &.is-active {
          z-index: 3;
          &:hover {
            z-index: 4; } } }
      &.is-expanded {
        flex-grow: 1; } }
    &.has-addons-centered {
      justify-content: center; }
    &.has-addons-right {
      justify-content: flex-end; }
    &.has-addons-fullwidth {
      .control {
        flex-grow: 1;
        flex-shrink: 0; } } }
  &.is-grouped {
    display: flex;
    justify-content: flex-start;
    & > .control {
      flex-shrink: 0;
      &:not(:last-child) {
        margin-bottom: 0;
        margin-right: 0.75rem; }
      &.is-expanded {
        flex-grow: 1;
        flex-shrink: 1; } }
    &.is-grouped-centered {
      justify-content: center; }
    &.is-grouped-right {
      justify-content: flex-end; }
    &.is-grouped-multiline {
      flex-wrap: wrap;
      & > .control {
        &:last-child,
        &:not(:last-child) {
          margin-bottom: 0.75rem; } }
      &:last-child {
        margin-bottom: -0.75rem; }
      &:not(:last-child) {
        margin-bottom: 0; } } }

  &.is-horizontal {
    @include tablet {
      display: flex; } } }

.field-label {
  .label {
    font-size: inherit; }
  @include mobile {
    margin-bottom: 0.5rem; }
  @include tablet {
    flex-basis: 0;
    flex-grow: 1;
    flex-shrink: 0;
    margin-right: 1.5rem;
    text-align: right;
    &.is-small {
      font-size: $size-small;
      padding-top: 0.375em; }
    &.is-normal {
      padding-top: 0.375em; }
    &.is-medium {
      font-size: $size-medium;
      padding-top: 0.375em; }
    &.is-large {
      font-size: $size-large;
      padding-top: 0.375em; } } }

.field-body {
  .field .field {
    margin-bottom: 0; }
  @include tablet {
    display: flex;
    flex-basis: 0;
    flex-grow: 5;
    flex-shrink: 1;
    .field {
      margin-bottom: 0; }
    & > .field {
      flex-shrink: 1;
      &:not(.is-narrow) {
        flex-grow: 1; }
      &:not(:last-child) {
        margin-right: 0.75rem; } } } }

.control {
  font-size: $size-normal;
  position: relative;
  text-align: left;
  // Modifiers
  // DEPRECATED
  &.has-icon {
    .icon {
      color: $input-icon-color;
      height: 2.25em;
      pointer-events: none;
      position: absolute;
      top: 0;
      width: 2.25em;
      z-index: 4; }
    .input {
      &:focus {
        & + .icon {
          color: $input-icon-active-color; } }
      &.is-small {
        & + .icon {
          font-size: $size-small; } }
      &.is-medium {
        & + .icon {
          font-size: $size-medium; } }
      &.is-large {
        & + .icon {
          font-size: $size-large; } } }
    &:not(.has-icon-right) {
      .icon {
        left: 0; }
      .input {
        padding-left: 2.25em; } }
    &.has-icon-right {
      .icon {
        right: 0; }
      .input {
        padding-right: 2.25em; } } }
  &.has-icons-left,
  &.has-icons-right {
    .input,
    .select {
      &:focus {
        & ~ .icon {
          color: $input-icon-active-color; } }
      &.is-small ~ .icon {
        font-size: $size-small; }
      &.is-medium ~ .icon {
        font-size: $size-medium; }
      &.is-large ~ .icon {
        font-size: $size-large; } }
    .icon {
      color: $input-icon-color;
      height: 2.25em;
      pointer-events: none;
      position: absolute;
      top: 0;
      width: 2.25em;
      z-index: 4; } }
  &.has-icons-left {
    .input,
    .select select {
      padding-left: 2.25em; }
    .icon.is-left {
      left: 0; } }
  &.has-icons-right {
    .input,
    .select select {
      padding-right: 2.25em; }
    .icon.is-right {
      right: 0; } }
  &.is-loading {
    &::after {
      @include loader;
      position: absolute !important;
      right: 0.625em;
      top: 0.625em; }
    &.is-small:after {
      font-size: $size-small; }
    &.is-medium:after {
      font-size: $size-medium; }
    &.is-large:after {
      font-size: $size-large; } } }
