.landing-page {
    &__overlay {
        background-color: #061651;
        position: fixed;
        width: 100vw;
        height: 100vh;
        display: none;
        z-index: 3;
        padding-top: 70px;
        transition: opacity .5s;

        &.active {
            display: block;
        }
    }

    &__nav {
        position: fixed;
        z-index: 4;
        background-color: #061651;
        padding-top: 15px;
        padding-bottom: 15px;
        width: 100%;
        top: 0;
    }

    &__hamburger {
        text-align: right;
        margin-top: 3px;

        .hamburger {
            padding: 0;

            &-inner,
            &-inner::before,
            &-inner::after {
                background-color: #d38900;
                height: 3px;
            }

            &:hover {
                opacity: 1;
            }

            &:focus {
                outline: none;
            }
        }
    }

    &__links {
        margin-top: 5px;
        text-align: right;
    }

    &__link {
        display: inline-block;
        padding-left: 40px;

        &-text {
            color: white;
            font-family: Helvetica, sans-serif, Arial;
            font-size: 1.1rem;
            font-weight: normal;

            &:hover {
                color: #d38900;
                text-decoration: none;
            }
        }

        &--mobile {
            display: block;
            margin-bottom: 10px;
        }

        @media (max-width: 1259px) {
            padding-left: 10px;
        }
    }

    &__header {
        background-image: linear-gradient(#223a8c, #061651);
        padding-top: 120px;
        padding-bottom: 0px;
        overflow: hidden;

        @media (max-width: 991px) {
            padding-top: 140px; 
            padding-bottom: 60px;
        }

        @media (max-width: 767px) {
            padding-top: calc(6vh + 63px);
            padding-bottom: 0;
            height: 100vh;
            text-align: center;
        }

        @media (min-width: 992px) and (max-width: 1200px) {
            padding-bottom: 70px;
        }
    }

    &__heading {
        font-size: 2rem;
        font-weight: normal;
        font-family: Helvetica, Arial, sans-serif;
        margin-top: 0;
        margin-bottom: 20px;
        color: #061651;

        &--white {
            @extend .landing-page__heading;
            font-size: 2.4rem;
            color: white;
        }

        &--orange {
            @extend .landing-page__heading;
            color: #d38900;
        }

        @media (max-width: 991px) {
            font-size: 1.6rem;
        }
    }

    &__text {
        font-family: Helvetica, Arial, sans-serif;
        font-size: 1rem;
        font-weight: bold;
        color: #061651;
        margin-bottom: 10px;

        &--white {
            @extend .landing-page__text;
            color: white;
        }

        &--orange {
            @extend .landing-page__text;
            color: #d38900;
        }

        &--thin {
            font-weight: normal;
        }

        &--narrow {
            max-width: 440px;
            margin: 0px auto 10px;
        }

        &--copyright {
            margin-bottom: 5px;
        }
    }

    &__app-store {
        margin-top: 20px;
        margin-bottom: 20px;
        display: block;

        @media (max-width: 767px) {
            margin-right: auto;
            margin-left: auto;
        }

        @media (min-width: 1200px) {
            margin-top: 40px;
        }
    }

    &__play-store {
        display: block;

        @media (max-width: 767px) {
            margin-right: auto;
            margin-left: auto;
        }
    }

    &__image {
        margin-bottom: 15px;

        &--push-down {
            margin-bottom: 0;
            position: relative;
            z-index: 2;

            @media (min-width: 768px) {
                margin-bottom: -130px;
                margin-top: 30px;
            }

            @media (min-width: 992px) {
                margin-bottom: -170px;
                margin-top: 40px;
            }
        }
    }

    &__coming-soon {
        color: white;
        border: 1px solid white;
        padding-top: 10px;
        padding-bottom: 10px;
        border-radius: 5px;
        font-weight: bold;
        width: 140px;
        margin: 0 auto 30px;
    }

    &__line {
        background-color: #d38900;
        margin-top: 40px;
        margin-bottom: 30px;

        @media (min-width: 768px) {
            margin-bottom: 40px;
        }
    }

    &__tvs {
        margin-top: 50px;

        @media (min-width: 768px) {
            margin-top: 56px;
            margin-right: 15px;
        }
    }

    &__tv {
        background-color: white;
        border-radius: 5px;
        text-align: left;
        padding: 15px 20px;
        position: relative;
        margin-bottom: 20px;

        &-name {
            @extend .landing-page__text;
            margin: 0;
            font-weight: normal;
        }

        &-notes {
            @extend .landing-page__text;
            color: grey;
            font-size: 0.9rem;
            font-weight: normal;
            margin: 0;
        }

        &-camera {
            position: absolute;
            right: 20px;
            top: 50%;
            margin-top: -20px;
        }

        &--2 {
            @extend .landing-page__tv;
            opacity: .7;
        }

        &--3 {
            @extend .landing-page__tv;
            opacity: .2;
            margin-bottom: 0;
        }
    }

    &__section {
        padding-top: 40px;
        padding-bottom: 40px;
        text-align: center;

        &--blue {
            text-align: center;
            padding-top: 30px;
            padding-bottom: 20px;
            background-color: #061651;
        }

        @media (min-width: 768px) {
            padding-top: 60px;
            padding-bottom: 60px;
        }

        &--grey {
            @extend .landing-page__section;
            background-color: #eaeaea;

            @media (min-width: 992px) {
                padding-top: 100px;
            }
        }

        &--surveyors {
            @extend .landing-page__section;
            background-image: url(/images/map.jpg);
            background-size: cover;
            background-repeat: no-repeat;
            padding-bottom: 200px;

            @media 
                (-webkit-min-device-pixel-ratio: 2), 
                (min-resolution: 192dpi) { 
                    background-image: url(/images/map@2x.jpg);
            }

            @media (min-width: 768px) {
                padding-top: 40px;
                padding-bottom: 300px;
            }

            @media (min-width: 1200px) {
                padding-bottom: 400px;
            }            
        }

        &--fast-track {
            position: relative;
            padding-bottom: 200px;
            
            @media (min-width: 568px) {
                padding-bottom: 300px;
            }

            @media (min-width: 768px) {
                padding-bottom: 400px;
            }

            @media (min-width: 992px) {
                padding-bottom: 600px;
            }
        }
    }

    &__side-image {
        margin-bottom: -60px;
        float: right;

        @media (max-width: 767px) {
            margin-top: 30px;
            float: none;
        }
    }

    &__side-text {
        @media (min-width: 1200px) {
            margin-top: 50px;
        }
    }

    &__stores {
        position: relative;
        background-color: white;
        border-radius: 5px;
        margin-top: 50px;
        padding: 20px;

        @media (max-width: 767px) {
            margin-top: 20px;
            padding: 0px 15px;
        }

        @media (min-width: 768px) {
            margin-left: 15px;
        }
    }

    &__store {
        text-align: left;
        border-bottom: 1px solid lightgrey;
        padding-top: 15px;
        padding-bottom: 15px;

        &-price {
            color: #061651;
            font-size: 1rem;
            font-family: Helvetica, Arial, sans-serif;
            font-weight: bold;
            float: right;
        }

        &--last {
            @extend .landing-page__store;
            border-bottom: 0;
        }
    }

    &__triangle {
        position: absolute;
        bottom: -27px;
        left: 50%;
        width: 40px;
        margin-left: -20px;

        @media (min-width: 768px) {
            margin-left: 0;
            left: -27px;
            transform: rotate(90deg);
            top: 50%;
            margin-top: -30px;
        }
    }

    &__fast-track {
        position: absolute;
        bottom: 0;
        left: 0;

        @media (min-width: 768px) {
            width: 91vw;
        }
    }
}