
// Fonts
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);
@import url(https://fonts.google.com/specimen/Libre+Baskerville);
@import url(https://fonts.googleapis.com/css?family=Libre+Baskerville);

// Variables
@import "variables";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

// Bulma
@import "node_modules/bulma/bulma";

//Chartist
@import "node_modules/chartist/dist/scss/chartist.scss";

//Datetimepicker
@import "node_modules/eonasdan-bootstrap-datetimepicker/src/sass/_bootstrap-datetimepicker.scss";

// Hamburgers
@import '~hamburgers/dist/hamburgers.min.css';

#app{
    min-height: 100vh;
    background-color: #EFEFF4;
}

.hero.is-primary .tabs.is-boxed li.is-active a {
    background-color: #EFEFF4;

    &:hover {
        background-color: #EFEFF4;
    }
}

.fit-100x100 {
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 100px;
    margin-bottom: 30px;

    @media (min-width: 1440px) {
        height: 160px;
    }
}
.fit-150x150 {
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 150px;
    margin-bottom: 30px;

    @media (min-width: 1440px) {
        height: 190px;
    }
}
.fit-200x200 {
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 200px;
    margin-bottom: 30px;

    @media (min-width: 1440px) {
        height: 260px;
    }
}
.ddm{
    min-width: 100px;
}

.w-100 {
    width: 100% !important;
}

.w-10 {
    min-width: 10% !important;
}

.pagination{
    max-width:150px;
    padding-bottom: 15px;
}

.section{
    padding: 0px;
    margin: 0px;
}

.padleft{
    padding-left: 20px;
}

.padding-for-container{
    padding-left: 100px;
    padding-right: 100px;
    padding-top: 50px;
    padding-bottom: 50px;
}

.maxwidth{
    max-width: 600px;
}
.maxwidth3{
    max-width: 400px;
}
.maxwidth4{
    max-width: 230px;
}
.maxwidth2{
    max-width: 200px;
}

.navbar{
    margin-bottom: 2px;
}

.mainhero{
    padding: 0px;
    z-index: 1;
    position: relative;
}

.btn-pull-right{
    margin-left: 90%;
    margin-bottom: 5px;
}

@media(min-width: 900px){
    .modal-open {
        padding-right: 0px !important;
    }
}

#description_of_damage{
    margin: 0px; width: 520px; height: 86px;
}

.pad-bot-10{
    padding-bottom:10px;
}

//change pagination colour
.pagination > .active > a, .pagination > .active > a:hover, .pagination > .active > a:focus, .pagination > .active > span, .pagination > .active > span:hover, .pagination > .active > span:focus {
    z-index: 3;
    color: #fff;
    background-color: $primary;
    border-color: #ddd;
}
.pagination > li > a, .pagination > li > span {
    color: $primary;
}
.btn-info {
    color: #000;
    background-color: #fff;
    border-color: $info; /*set the color you want here*/
}

.button.is-info:hover, .button.is-info.is-hovered{
    background-color: $primary;
    color: white !important;
    text-decoration: none;
}

.button.is-success:hover, .button.is-success.is-hovered{
    background-color: #fff;
    border: 1px solid $success;
    color: $success !important;
    text-decoration: none;
}

.button.is-warning{
       background-color: $primary;
       color: #fff;
}

.button.is-warning:hover, .button.is-warning.is-hovered{
    background-color: #fff;
    border: 1px solid $primary;
    color: $primary !important;
    text-decoration: none;
}

.no-stretch{
    object-fit: cover;
}



.btn-info:hover, .btn-info:focus, .btn-info:active, .btn-info.active, .open>.dropdown-toggle.btn-info {
    color: #fff;
    background-color: $info;
    border-color: #000; /*set the color you want here*/
}

.fa{
    vertical-align: middle;
}

#map{
    height: 100%;
}

.charty{
    padding-top: 30px;
}

.longone{
    width:100%;
}
.fullone{
    width: 100%;
    min-height: 600px;
}

#primaryC{
   color: $primary;
}
#infoC{
    color: $info;
}
#dangerC{
    color: $success;
}

.is-text-blue{
    color: $primary !important;
}

.title-span{
    display: block;
    background-color: whitesmoke;
}

.sidepadding{
    margin-left: 3px;
    margin-right: 5px;
    margin-bottom: 0 !important;
    color: $success;
    background-size: cover;
    background-position: center;
}
.not-too-left{
    margin-left: 3px;
}

.not-too-left-2{
    height: 3em;
    overflow: hidden;
    display: block;
}


/* bootstrap hack: fix content width inside hidden tabs */
.tab-content > .tab-pane,
.pill-content > .pill-pane {
    display: block;     /* undo display:none          */
    height: 0;          /* height:0 is also invisible */
    overflow-y: hidden; /* no-overflow                */
}
.tab-content > .active,
.pill-content > .active {
    height: auto;       /* let the content decide it  */
} /* bootstrap hack end */
.modal-backdrop {
    z-index: -1;
}


/* Arrows */
.slick-prev,
.slick-next
{
    font-size: 0;
    line-height: 0;

    position: absolute;
    top: 50%;

    display: block;

    width: 20px;
    height: 20px;
    padding: 0;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);

    cursor: pointer;

    color: transparent;
    border: none;
    outline: none;
    background: transparent;
}
.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus
{
    color: transparent;
    outline: none;
    background: transparent;
}
.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before
{
    opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before
{
    opacity: .25;
}

.slick-prev:before,
.slick-next:before
{
    font-family: 'slick';
    font-size: 20px;
    line-height: 1;

    opacity: .75;
    color: white;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.slick-prev
{
    left: -15px;
}
[dir='rtl'] .slick-prev
{
    right: -15px;
    left: auto;
}
.slick-prev:before
{
    content: '←';
}
[dir='rtl'] .slick-prev:before
{
    content: '→';
}

.slick-next
{
    right: -15px;
}
[dir='rtl'] .slick-next
{
    right: auto;
    left: -15px;
}
.slick-next:before
{
    content: '→';
}
[dir='rtl'] .slick-next:before
{
    content: '←';
}

/* Dots */
.slick-dotted.slick-slider
{
    margin-bottom: 30px;
}

.slick-dots
{
    position: absolute;
    bottom: -25px;

    display: block;

    width: 100%;
    padding: 0;
    margin: 0;

    list-style: none;

    text-align: center;
}
.slick-dots li
{
    position: relative;

    display: inline-block;

    width: 20px;
    height: 20px;
    margin: 0 5px;
    padding: 0;

    cursor: pointer;
}
.slick-dots li button
{
    font-size: 0;
    line-height: 0;

    display: block;

    width: 20px;
    height: 20px;
    padding: 5px;

    cursor: pointer;

    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
}
.slick-dots li button:hover,
.slick-dots li button:focus
{
    outline: none;
}
.slick-dots li button:hover:before,
.slick-dots li button:focus:before
{
    opacity: 1;
}
.slick-dots li button:before
{
    font-family: 'slick';
    font-size: 6px;
    line-height: 20px;

    position: absolute;
    top: 0;
    left: 0;

    width: 20px;
    height: 20px;

    content: '•';
    text-align: center;

    opacity: .25;
    color: black;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before
{
    opacity: .75;
    color: black;
}
.slick-current
{
    min-height: 500px !important;
    min-width: 608px !important;
}
.slick-prev:before, .slick-next:before { font-family: FontAwesome; font-size: 25px; line-height: 1; color: #363636; opacity: 0.75; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; }

.slick-prev:before { content: "\f053"; }
[dir="rtl"] .slick-prev:before { content: "\f054"; }

[dir="rtl"] .slick-next { left: -10px; top: 70px; right: auto; }
.slick-next:before { content: "\f054"; }
[dir="rtl"] .slick-next:before { content: "\f053"; }

.slick-image{
    background-size: contain;
    background-repeat: no-repeat;

}

.site {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
}

.site-content {
    flex: 1;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
}

.image-logo{
        margin: 0 auto;
        max-width: 570px;
        text-align: center;
        float: none;
}

.has-text-muted {
    color: #95A5A6;
}
.fa {
    font-size:10px;
    padding-top:3px;
    color: #95A5A6;
}
.panel-block-item  {
    display: inline-block;
    color: #95A5A6;
    font-weight: bold;
    padding-right: 10px;
}

.panel-block-item .tag,.panel-block-item .button  {
    color: #95A5A6;
    font-weight: bold;
}

.panel-block-item.is-right {
    display: inline-block;
    color: #95A5A6;
    font-weight: bold;
    float: right;
}

.panel-block-item .likes {
    padding-right: 25px;
}

.panel-block-item .likes .fa,
.panel-block-item .comments .fa
{
    font-size: 15px;
    margin-top: -2px;
}

.avatar {
    border-radius: 42px;
    margin-right: 10px;
    height: 42px;
    width: 42px;
    padding: 2px;
    border: 1px solid #dbdbdb;
}

.timestamp {
    color: #95A5A6;
    font-weight: bold;
}

#mediaID video
{
    display:none;
}

.imageprop{
    max-height: 380px !important;
}

.cardimentions{
    max-height: 500px !important;
    max-width: 500px !important;
}

.fullone{
    height: 500px;
}

.navbar-rhs{
    float: right !important;
    margin-right: -15px;
    margin-bottom: 15px;
}

.pagination-right{
    margin-bottom: 10px !important;
}

.left-pad{
   margin-left: 5px;
}

.is-check{
    background-color: red;
}

.is-times{
    background-color: green;
}

.is-text-black{
    color: black;
}

.box-info{
    margin: 0px;
    padding-top: 0px;
    .fa-stack-1x{
        font-size: 0.3em;
        line-height: 22px;
        color: $primary;
    }
    .fa-stack-2x{
         font-size: 0.5em;
         color: $info;
     }
    .fa-stack-3x{
        font-size: 0.5em;
    }
    .fa-stack{
        width: 0.5em;
        height: 0.5em;
    }
    .fa{
        padding-top: 0px !important;
    }
}
#createform .box{
    padding: 0;

    .spacing{
        padding: 1.25rem;
    }
    .active{
        border: 4px solid #000;
        background-color: #fff;
        color: #000;
    }
}


.popup {
    position: fixed;
    z-index: -1;
    background-color: rgba(0, 0, 0, 0.75);
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    opacity: 0;

    transition: all .5s;

    &.active {
        opacity: 1;
        z-index: 100;
    }

    .center-content {
        margin: 0 auto;
        width: 800px;
        margin-top: 15vh;
        color: white;

        h2, h4 {
            font-size: 30px;
            font-weight: bold;
        }

        h3 {
            font-size: 15px;
            margin-bottom: 10px;
        }

        h5 {
            margin-top: 15px;
            font-size: 15px;
        }
    }
}

.subtitle-grey, .subtitle strong {
    color: #A4AAB3 !important;
}

.little-bottom-padding {
    padding-bottom: 20px;
}
.no-padding {
    padding: 0px;
}
.clickable-image{
    cursor: pointer;
    background-color: black;
    background-position: center;
}
#inspectionModal{
    background-color: rgba(0,0,0,0.75);
    padding: 0!important;
    
    .no-margin{
        margin-left: 0;
        margin-right: 0;
    }
    #item-name{
        font-size:2.6em;
        font-weight: bolder;
    }
    #item-price{
        font-size: 1.7em;
        font-family: initial;
        font-weight: bold;
    }
    .modal-dialog {
      display: inline-block;
      text-align: left;
      vertical-align: middle;
      width: 100%;
      position: fixed;
    }
    .modal-body{
        padding: 0px!important;
    }
    .modal-content{
        height: auto;
        width: auto;
        max-width: 70%
    }
    #inspection-image-container{
        background-color: #0a0a0a;
    }
    #inspection-image, #inspection-video{
        margin: auto;
        max-height: 89vh;
    }
    #inspection-video{
        position: relative;
        top: 50%;
        transform: translateY(-50%);
    }
    .full-height{
        height: 100%;
    }
    .inspection-title h3{
        font-family: initial;
        font-size: 1.4em;
    }
    .inspection-sub{
        margin: 15px auto;
        
        h4{
            color:#9d9d9d;
        }
    }
    .inspection-info{
        padding: 15px;
        background-color: white;
        display: flex;
        flex-direction: column;
    }
    .inspection-row{
        background-color: #0a0a0a;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        flex-wrap: wrap;
    }
    hr{
        height: 2px;
    }
    .inspection-geocode-bottom{
        position: absolute;
        bottom: 0;
    }
    
}

.faces{
    padding: 5px 5px 5px 0;
}
.share-hero{
    letter-spacing: 1.6px;
    h2.subtitle{
        color: white;
        // font-family: 'Libre Baskerville'!important;
    }
}
.share.card{
    background-color:rgba(0,0,0,0);
    box-shadow: none;
}
.share .card-content {
    padding-left: 0;
}
.title{
    font-family: inherit;
    // font-family: 'Libre Baskerville'!important;
    color: #00173B;
}
.no-padding-left{
    padding-left: 0;
}
.latLong{
    font-size: 0.9em;
}
.share-hr{
    background-color: #9fa2af;
}
.share-download,
.image-logo{
    margin: 25px 15px 25px 15px;
    color: #00173B!important;
    font-weight: bold;
}
.share-lightbox-download{
    margin: 7px 0;
    color: #00173B!important;
    font-weight: bold;
}
.close-modal{
    position: absolute;
    top: 0px;
    left: 20px;
    max-width: 50px;
    max-height: 50px;
    z-index: 9999;
}
#closeModal{
    color: white;
    font-size: 2.7rem;
    cursor: pointer;
    transition-duration: 0.4s;
    font-style: normal;
}
#closeModal:hover{
    color: #9f9f9f;
}
.header-hr{
    margin: 0;
    height: 2px;
    background-color: rgba(255, 255, 255, 0.32);
}
@media screen and (max-width: 780px){
    #inspectionModal{
        .modal-content{
        max-width: 100vw;
        max-height: 95vh;
        }
        .inspection-info{
            max-height: 38vh;
        }
        #inspection-image, #inspection-video{
            max-height: 60vh
        }
    }
}
@media screen and (max-width: 1000px){
    #inspectionModal{
        .inspection-geocode-bottom{
            position: relative;
        }  
    }
}
#inspectionModal:before {
      content: '';
      display: inline-block;
      height: 100%;
      vertical-align: middle;
      margin-right: -4px;
    }
.inspection.modal-dialog{
    
}

.text-secondary {
    color: $info;
}

.text-brand-primary {
    color: $primary;
}

.bg-brand-primary {
    background-color: $primary;
}

.bg-brand-secondary {
    background-color: $info;
}

.b-0 {
    border: 0 !important;
}

.font-bold {
    font-weight: bold;
}

.button-brand-primary {
    background-color: $primary;
    color: white;
}

.button-brand-primary:hover {
    text-decoration: none;
    background-color: lighten($primary, 5%);
    color: white;
}

.button-brand-secondary {
    background-color: $info;
    color: white;
}

.button-brand-secondary:hover {
    text-decoration: none;
    background-color: darken($info, 10%);
    color: white;
}

.font-20px {
    color: $primary;
    font-size: 20px;
    font-weight: bold;
}


.pill {
    cursor: pointer;
    padding: 6px 10px 6px 10px;
}

.mt-5 {
    margin-top: 2.5em;
}

.mt-2 {
    margin-top: 1em;
}

.d-none {
    display: none;
}

.center{
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.mlr-auto {
    margin-left: auto;
    margin-right: auto;
}

.w-145px {
    width: 145px;
}

.w-300px {
    width:300px;
}

.p-0 {
    padding: 0px !important;
}

.p-2 {
    padding: 1em !important;
}

.m-1 {
    margin: 0.5em !important;
}

.is-65 {
    -webkit-box-flex: 0;
    -ms-flex: 0;
    flex: none;
    width: 65%;
}

.is-35 {
    -webkit-box-flex: 0;
    -ms-flex: 0;
    flex: none;
    width: 35%;
}

.d-none-i {
    display: none !important;
}

.section-item {
    position:absolute;
    top:0;
    width:30px;
    height:30px;
    background-color:$primary;
    border-radius:50%;
    border:5px solid $info;

    div {
        color: #fff;
    }

    &-text {
        position:absolute;
        top:30px;
    }
    
    &-selected {
        background-color:$info !important;

        div {
            color: $info !important;
        }
    }
}

.left--10px {
    left: -10px;
}

.left--15px {
    left:-15px;
}

.left-0 {
    left:0;
}

.right-0 {
    right:0;
}

.section-line {
    display:block;
    height:4px;
    position:absolute;
    top:50%;
    transform: translateY(-50%);
    background-color:$info !important;
}

.rel-center-40 {
    line-height:30px;
    height:30px;
    position:relative;
}

.line-item-underline {
    height:1px;
    background-color: #9d9d9d;
}

.is-text-light {
    color: #9d9d9d;
}

.w-50 {
    width: 50%;
}

.full-screen {

    position: fixed;
    width: 100vw;
    height: 100vh;
    top:0;
    left:0;
    overflow: hidden;
    background-color: #363636; 
    opacity: 0.9;
    z-index: 1000;

    &-text {
        color: white;
        position:absolute;
        top:50%;
        transform: translateY(-50%);
        text-align: center;
        width:100vw;
        font-size: 30px;
    }
}

.f-24px {
    font-size: 24px;
}

.mr-3 {
    margin-right: 1.5em;
}

.font-libre {
    // font-family: "Libre Baskerville" !important;
}

.user-more-details{
    font-size: 10px;
    font-weight: bold;
    text-transform: capitalize;
    color: #9B9B9B;
}

.currentquestions{
    padding-top: 3px;
}
.question{
    padding-bottom: 5px;
}
.small-text-square{
    float: left;
    width: 20px;
    height: 20px;
    margin: 5px;
    border: 1px solid rgba(0, 0, 0, .2);
}
.small-text-square-text{
    margin-top: 3px;
    display: inline-block;
}
.piechartclass0{
    fill: $primary;
    color: white;
}
.piechartclass1{
    fill: $info;
    color: white;
}
.piechartclass2{
    fill: #9B9B9B ;
    color: $primary;
}
.keyclass0{
     background: $primary;
 }
.keyclass1{
    background: $info;
}
.keyclass2{
    background: #9B9B9B;
}
.ct-label{
    color: white;
    fill: white;
}
.line-pie-div{
    height:30px;
}
.map-pin-key{
    height: 20px;
}
.for-checkbox{
    height: 30px;
}
.accepted-title {
    font-size: 22px;
    width: 300px;
    color: $primary;
    margin: 0 auto;

    @media (max-width: 370px) {
        width: 100%;
    }
}
.accepted-body {
    color: $primary;
    width: 400px;
    margin: 30px auto;

    @media (max-width: 420px) {
        width: 100%;
    }
}
.accepted-icon {
    display: inline-block !important;
    color: $primary;
    font-size: 36px !important;
    width: 100%;
    text-align: center;
    margin-bottom: 50px;
}

ul.select2-results__options {
    max-height: 400px !important;
}

.redactor-styles p {
    margin-bottom: 0 !important;
}

@import "pages/job-create";

@import "pages/job-edit";

@import 'pages/landing-page';