$control-radius: $radius !default;
$control-radius-small: $radius-small !default;

$control-padding-vertical: calc(0.375em - 1px) !default;
$control-padding-horizontal: calc(0.625em - 1px) !default;

@mixin control {
  -moz-appearance: none;
  -webkit-appearance: none;
  align-items: center;
  border: 1px solid transparent;
  border-radius: $control-radius;
  box-shadow: none;
  display: inline-flex;
  font-size: $size-normal;
  height: 2.25em;
  justify-content: flex-start;
  line-height: 1.5;
  padding-bottom: $control-padding-vertical;
  padding-left: $control-padding-horizontal;
  padding-right: $control-padding-horizontal;
  padding-top: $control-padding-vertical;
  position: relative;
  vertical-align: top;
  // States
  &:focus,
  &.is-focused,
  &:active,
  &.is-active {
    outline: none; }
  &[disabled] {
    cursor: not-allowed; } }

// The controls sizes use mixins so they can be used at different breakpoints
@mixin control-small {
  border-radius: $control-radius-small;
  font-size: $size-small; }
@mixin control-medium {
  font-size: $size-medium; }
@mixin control-large {
  font-size: $size-large; }
